import firebase from "firebase";
require("dotenv").config();

var firebaseConfig = {
  apiKey: "AIzaSyA4OksiFgixp7RY6J6gJ1bARL9iy-3opfc",
    authDomain: "testapp-9c1a7.firebaseapp.com",
    projectId: "testapp-9c1a7",
    storageBucket: "testapp-9c1a7.appspot.com",
    messagingSenderId: "965823125965",
    appId: "1:965823125965:web:c26a21ee3ce38135168c84"
};

// Initialize Firebase
var fireDb = firebase.initializeApp(firebaseConfig);

export default fireDb.firestore();
