import './App.css';
import AppRouter from "./AppRouter.js"

function App() {
  return (
    <div className="App mainpage">
      <AppRouter />
    </div>
  );
}
export default App;
