import React, { useState, useEffect } from "react";
import "./admin.css";
import { Redirect } from "react-router-dom";
import firebaseDb from "../../firebase.js";
require("dotenv/config");

function AdminForm() {
  const initialData = {
    date: new Date(),
    t1: "",
    t2: "",
    t3: "",
    t4: "",
    t5: ""
  };

  const getTodayDate = () => {
    var today = new Date();
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var date = today.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (date < 10) {
      date = "0" + date;
    }
    var finalDate = year + "-" + month + "-" + date;
    return finalDate;
  };

  const todayDate = getTodayDate();
  const [data, setData] = useState(initialData);
  const [id, setID] = useState(null);

  const getData = (date) => {
    firebaseDb
      .collection("/data")
      .where("date", "==", date)
      .onSnapshot((a) => {
        if (a.size > 0) {
          a.forEach((i) => {
            setData(i.data());
            setID(i.id);
          });
        } else {
          let tempData = initialData;
          tempData.date = data.date;
          setData(tempData);
          setID(null);
        }
      });
  };

  const handleDate = (e) => {
    var obj = data;
    obj.date = e.target.value;
    getData(e.target.value);
    setData({ ...data, ...obj });
  };

  const handleInput = (e, key) => {
    var obj = data;
    obj[key] = e.target.value;
    setData({ ...data, ...obj });
  };

  const submit = () => {
    getData(data.date);
    if (id != null) {
      firebaseDb.collection("/data").doc(id).set(data);
    } else {
      firebaseDb.collection("/data").add(data);
    }
  };

  const logout = () => {
    localStorage.removeItem("5plusoneToken");
    return window.location.replace("/");
  };

  const local = localStorage.getItem("5plusoneToken");

  useEffect(() => {
    getData(todayDate);
  }, []);

  if (local !== undefined && local !== null && local.length > 5) {
    return (
      <div className="adminForm">
        <div class="form-group">
          <div class="col-sm-10">
            <input
              type="date"
              value={data.date}
              onChange={handleDate}
              class="form-control datepicker"
              id="Date"
              placeholder="Enter Date"
              name="date"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-10">
            <input
              type="string"
              value={data.t1}
              onChange={(e) => handleInput(e, "t1")}
              class="form-control"
              id="t1"
              placeholder="12:00 PM"
              name="one"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-10">
            <input
              type="string"
              value={data.t2}
              onChange={(e) => handleInput(e, "t2")}
              class="form-control"
              id="t2"
              placeholder="2:00 PM"
              name="two"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-10">
            <input
              type="string"
              value={data.t3}
              onChange={(e) => handleInput(e, "t3")}
              class="form-control"
              id="t3"
              placeholder="4:00 PM"
              name="three"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-10">
            <input
              type="string"
              value={data.t4}
              onChange={(e) => handleInput(e, "t4")}
              class="form-control"
              id="t4"
              placeholder="5:00 PM"
              name="three"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-10">
            <input
              type="string"
              value={data.t5}
              onChange={(e) => handleInput(e, "t5")}
              class="form-control"
              id="t5"
              placeholder="7:00 PM"
              name="three"
            />
          </div>
        </div>
    
        <div class="form-group">
          <div class="col-sm-offset-2 col-sm-10">
            <button onClick={submit} class="btn btn-success">
              Submit
            </button>
            <button onClick={logout} class="btn btn-danger btnLog">
              Logout
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect path="/" />;
  }
}

export default AdminForm;
