import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './components/homePage/homePage'
import Navbar from './components/navbar/navbar'
import Admin from './components/admin/admin'
import AdminForm from './components/admin/adminForm'

class AppRouter extends Component {
    render() {
        return (
            <Router>
                <Navbar />
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/adminlogin" component={Admin} />
                    <Route exact path="/admin" component={AdminForm} />
                </Switch>
            </Router>
        )
    }
}

export default AppRouter