import React, { useState } from "react";
import "./admin.css";
import { Redirect } from "react-router-dom";
import firebaseDb from "../../firebase.js";

function Admin() {
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [redirect, setRedirect] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handlePass = (e) => {
    setPass(e.target.value);
  };

  const login = () => {
    firebaseDb
      .collection("/users")
      .orderBy("username")
      .onSnapshot((a) => {
        a.forEach((i) => {
          let finalData = i.data();
          if (finalData.username === name && finalData.password === pass) {
            var token =
              Math.floor(Math.random() * 999999999999999) + 100000000000000;
            localStorage.setItem("5plusoneToken", token);
            setRedirect(true);
          }
        });
      });
  };

  const local = localStorage.getItem("5plusoneToken");
  if (redirect === true) {
    return window.location.replace("/admin");
  }
  if (local !== null) {
    return <Redirect path="/admin" />;
  }

  return (
    <div className="adminLogin">
      <div class="form-group">
        <div class="col-sm-12">
          <input
            type="text"
            value={name}
            onChange={handleName}
            class="form-control"
            id="email"
            placeholder="Enter username"
            name="email"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-12">
          <input
            type="password"
            value={pass}
            onChange={handlePass}
            class="form-control"
            id="pwd"
            placeholder="Enter password"
            name="pwd"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-offset-2 col-sm-10">
          <button onClick={login} class="btn btn-success">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default Admin;
